module.exports = [{
      plugin: require('C:/Users/alm/WebDev/projects/gatsby/zlatara-alisa/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/alm/WebDev/projects/gatsby/zlatara-alisa/node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Početna","useClassNames":true},
    },{
      plugin: require('C:/Users/alm/WebDev/projects/gatsby/zlatara-alisa/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
