// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-template-js": () => import("C:\\Users\\alm\\WebDev\\projects\\gatsby\\zlatara-alisa\\src\\templates\\product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-category-template-js": () => import("C:\\Users\\alm\\WebDev\\projects\\gatsby\\zlatara-alisa\\src\\templates\\category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\alm\\WebDev\\projects\\gatsby\\zlatara-alisa\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\alm\\WebDev\\projects\\gatsby\\zlatara-alisa\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("C:\\Users\\alm\\WebDev\\projects\\gatsby\\zlatara-alisa\\src\\pages\\kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nama-js": () => import("C:\\Users\\alm\\WebDev\\projects\\gatsby\\zlatara-alisa\\src\\pages\\o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-proizvodi-js": () => import("C:\\Users\\alm\\WebDev\\projects\\gatsby\\zlatara-alisa\\src\\pages\\proizvodi.js" /* webpackChunkName: "component---src-pages-proizvodi-js" */),
  "component---src-pages-usluge-js": () => import("C:\\Users\\alm\\WebDev\\projects\\gatsby\\zlatara-alisa\\src\\pages\\usluge.js" /* webpackChunkName: "component---src-pages-usluge-js" */)
}

